import React from 'react'
import {Link, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"

const LanguageSelector = ({hideCurrent = false, className}) => {
    const { t, i18n } = useTranslation();
    const {languages, originalPath} = useI18next();

    const context = React.useContext(I18nextContext);
    const currentLanguage = context.language;

    // const changeLanguage = lng => {
    //     i18n.changeLanguage(lng);
    // };
    return (
        <div className={className}>
            <ul className="languages">
                {languages.map((lng) => {
                  if (hideCurrent && (lng == currentLanguage)) {
                    return
                  }
                  
                  return (
                    <li key={lng}>
                      <Link to={originalPath} language={lng}>
                        <ReactCountryFlag countryCode={lng == 'en' ? 'gb' : lng} style={{ fontSize: '1.5em', lineHeight: '2em', marginLeft: '10px'}} svg title={lng == 'en' ? 'English' : 'magyar'}/>
                      </Link>
                    </li>
                  )
                })}
              </ul>

        </div>
    )
}

export default LanguageSelector


// <div onClick={() => changeLanguage('en')}><ReactCountryFlag countryCode="GB" style={{ fontSize: '1.5em', lineHeight: '2em', marginLeft: '10px'}} svg title="English"/></div>
// <div onClick={() => changeLanguage('hu')}><ReactCountryFlag countryCode="HU" style={{ fontSize: '1.5em', lineHeight: '2em', marginLeft: '10px'}} svg title="Hungarian"/></div>