import React from 'react'
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"


import styled from "styled-components"

import ScrollTop from "./scrollTop"
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import LanguageSelector from './language-selector'

const FooterWrapper = styled.footer`
    background: #2c466b;
    color: white;
`

const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: left;
    max-width: 90%;
    display: flex;
    align-items: center;
    height: 40px;
`

const ContentInner = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    a {
        color: white;
        
        &:hover {
            color: #ff8000;
        }
    }
`


const Footer = (props) => {
    const {t} = useI18next()
    
    return (
        <FooterWrapper>
            <ContentWrapper>
                <ContentInner>    
                    <div>© {new Date().getFullYear()} CodeBreakers</div>
                    <div><Link to="/privacy-policy">{t("PRIVACY-POLICY.PRIVACY-POLICY")}</Link></div>
                </ContentInner>
            </ContentWrapper>
            <ScrollTop {...props}>
              <Fab color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
              </Fab>
          </ScrollTop>
        </FooterWrapper>
    )
}

export default Footer