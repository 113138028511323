import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"
import {
  Link,
} from "gatsby-plugin-react-i18next"


export const globalStyles = makeStyles({
  '@global': {
    '.geDiagramContainer': {
        marginTop: '100px!important',
    },
    '.geDiagramContainer + img': {
        zIndex: '100000!important',
        marginTop: '60px!important',
    },
    '.mainContainer': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 'calc(100vh - 131px)',
        justifyContent: 'space-between',
          ['@media screen and (max-width: 1220px)']: {
            minHeight: '100vh',
          }
    },
    '.main': {
        flex: '1 0 auto',
    },
    '.languages': {
        display: 'flex',
        listStyleType: 'none',
    }
  },
});


export const ContainerWithBackground = styled.div`
    width: 100%;
    min-height: calc(100vh - 40px);
    text-align: center;
    margin: 0 auto;
    margin-top: -131px;
    padding-top: 131px;

    background-image: url(${(props)=>props.backgroundImage});
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    
    @media screen and (max-width: 1100px) {
        position: relative;
        margin-top: 73px;
        padding-top: 0;
        min-height: calc(100vh - 113px);
        background-color: white;
        z-index: 10;
   
   
     }
`


export const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    max-width: 1372px;
    width: 90%;
    
    min-height: calc(100vh - 386px);
    min-height: calc(100vh - 236px);
    min-height: calc(100vh - 342px);

    padding-top:40px;
    background-color: rgba(255,255,255,1);
    padding-bottom: 60px;
    
    &.reduced-margin-top {
        margin-top: 5px;
    }
    
    &.transparent {
        background: transparent;
    }
    
    p { 
        
        font-size: 20px;
        line-height: 1.4;
        margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
        
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
`


export const ContentInner = styled.div`
    width: 73.306%;
    margin: 0 auto;
    
`

