/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import {
  I18nextContext
} from "gatsby-plugin-react-i18next"

import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const context = React.useContext(I18nextContext);
  const currentLanguage = context.language;

  const descriptionEN = "CodeBreakers takes behavioural economics further and turns the use of cognitive tools, codes and biases into behavioural business applications."
  const descriptionHU = "A CodeBreakers a Viselkedési közgazdaságtant továbbfejlesztve, a Viselkedési üzlet modell alapján segít jól használni a kognitív torzításokat és kódolásokat."
  
  const defaultDescription = (currentLanguage === "hu") ? descriptionHU : descriptionEN
  const metaDescription = description || defaultDescription

  const lang = (currentLanguage === "hu") ? "hu" : "en"
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}™`}
      defaultTitle="CodeBreakers™ – Be Conscious."
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO