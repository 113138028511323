import React, {useState, useRef, useEffect} from "react"
// import ReactCountryFlag from "react-country-flag"
import LanguageSelector from './language-selector';
import { graphql, useStaticQuery } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import slugify from 'slugify';

import { Link as ScrollToLink } from 'react-scroll'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types"
import Nav from "./nav"
import styled from "styled-components"
import Divider from '@material-ui/core/Divider';

import MenuIcon from '@material-ui/icons/Menu';
// const Container = styled.div`
//   // position: absolute;
//   position: relative;
//   width: 100%;
//   z-index: 150;
// `

const TopBarWrapper = styled.div`
  position: relative;
  z-index: 99;
  
  @media screen and (max-width: 1100px) {
    display: none;
  }
`

const TopBar = styled.div`
  width: 1372px;
  margin: 0 auto;
  max-width: 90%;
  background-color: rgba(44,70,107,1);
  color: rgba(234,241,243,0.85);

  @media screen and (max-width: 1220px) {
    max-width: 100%;
  }
`

const TopBarInner = styled.div`
  // width: 1180px;
  margin: 0 auto;
  max-width: 95%;
  padding: 5px 0;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SiteHeader = styled.header`
  z-index: 1200;
  background: transparent;
  position: sticky;
  top: 0;
  @media screen and (max-width: 1100px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
  }

`

const SiteHeaderContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  width: 1372px;
  margin: 0 auto;
  max-width: 90%;
  
  @media screen and (max-width: 1220px) {
    width: 100%;
    max-width: none;
  }
  
  @media screen and (max-width: 1100px) {
    background: transparent;
  }
  
  @media screen and (max-width: 900px) {
    
  }
`

const SiteHeaderInner = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  transition: all ease 0.3s;
  // width: 1180px;
  margin: 0 auto;
  max-width: 95%;
  display: flex;
  justify-content: space-between;
  
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    background: white;
    max-width: 100%;
  }
  
  @media screen and (max-width: 900px) {
    
    &::after {
      
    }
    
  }
`

const LogoWrapper = styled.div`
  user-select: none;
  width: max-content;
  
  @media screen and (max-width: 1100px) {
    margin: 10px auto;
    
  }
  
  a {
    &:hover {
      text-stroke: initial;
      -web-kit-text-stroke: initial;
      webkit-text-stroke-width: unset;
      -webkit-text-stroke-color: unset;
      -webkit-text-stroke-width:0;
    }
  }
`

const NavWrapper = styled.div`
    display: flex;
    align-items: center;
    // overflow: hidden;
    // @media screen and (max-width: 1100px) {
    //   justify-content: center;
    //   border-top: 1px solid rgb(44,70,107);
    //   padding-top: 5px;
    // }
    
    @media screen and (max-width: 1100px) {
      overflow: hidden;
      justify-content: center;
      transition: all 0.7s ease;
      
      &.burgerOn {
        // display: block;
        height: auto;
        max-height: 400px;
        background-color: rgba(44,70,107,1);
        nav {
          height: 100%;
        }
      }
      
      &.burgerOff {
        max-height: 0;
      }
    }
`

const Logo = styled.h1`
  position: relative;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  color: rgba(44,70,107,1);
  
  @media screen and (max-width: 1100px) {
      font-size: 24px;
  }
  
  a {
    text-stroke: initial;
    -web-kit-text-stroke: initial;
    webkit-text-stroke-width: unset;
    -webkit-text-stroke-color: unset;
  }
  
  
  .trademark {
    position: absolute;
    font-size: 12px;
    top: 5px;
  }
`

const Tagline = styled.h2`
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  text-align: right;
  color: #ff8000;
  
  @media screen and (max-width: 1100px) {
    font-size: 15px;
  }
  

  // color: #ff8000;
  // -webkit-text-stroke-width: 1px;
  // text-stroke: 1px;
  // -webkit-text-stroke-color: #ff8000;
`

const MainMenu = styled.ul`
  display: flex;
  
    @media screen and (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      padding-top: 16px;
      padding-bottom: 32px;
      
    }

`

const SubMenu = styled.ul`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 59px;
  background-color: rgba(44,70,107,1);
  transition: all 0.1s;
  transition-delay: 0.2s;
  // text-transform: capitalize;
  text-transform: initial;
  width: max-content;
  
    @media screen and (max-width: 1100px) {
      display: none;
    }
  
  li {
    list-style: none;
    color: rgba(234,241,243,1);
    padding: 10px 10px;
    // text-tranform: capitalize;
    text-transform: initial;
    border-bottom: solid 1px rgba(234,241,243,0.15);
    transition: background 0.3s ease;
    
    
    a {
      color: rgba(234,241,243,1);
      line-height: 1.45;
      margin-left: 5px;
      margin-right: 5px;
      width: 100%;
    }
  }
  
`

const MainMenuItem = styled.li`
  position: relative;
  list-style: none;
  text-transform: uppercase;
  margin-left: 15px;
  font-size: 16px;
  font-size: 1.2vw;
  font-size: clamp(15px, 1.2vw, 17px);
  font-size: clamp(15px, 1.1vw, 17px);
  font-size: 16px;
  
  @media screen and (max-width: 1300px) {
    margin-left: 14px;
    font-size: 15px;
  }

  @media screen and (max-width: 1220px) {
    margin-left: 13px;
    font-size: 15px;
  }

  @media screen and (max-width: 1100px) {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
    font-size: 22px;
  }
  
  a {
    color: rgba(44,70,107,1);
    // line-height: 73px;
    cursor: pointer;
    // &:hover {
    //   color: #ff8000!important;
    //   // text-shadow: 0.5px 0px 0px #ff8000, -0.5px 0px 0px #ff8000;
    // }
    
    @media screen and (max-width: 1100px) {
      color: white;
    }
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    background-color: #2c466b;
    // background-color: #ff8000;
    height: 0px;
    opacity: 0;
    // transition-property: all;
    // transition-duration: 0.5s;
    // transition-timing-function: ease;
    
      @media screen and (max-width: 992px) {
        display: none;
      }
  }
  
  &:hover {
    
    > a {
      color: #ff8000;
      -webkit-text-stroke-width: 1px;
      text-stroke: 1px;
      -webkit-text-stroke-color: #ff8000;
    }
    

    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      background-color: #2c466b;
      // background-color: #ff8000; 
      height: 3px;
      opacity: 1;
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }
  }
  
  & > .current-page::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    background-color: #2c466b;
    height: 2px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
  
  &.open-submenu {
    > ul {
      opacity: 1;
      visibility: visible;
      transition: all ease 0.3s;
      transition-delay: 0s;
    }
  }
`

const SubMenuItem = styled.li`
  &:hover {
    // background-color: rgba(70,130,180,1);
    a {
     color: #ff8000;
      -webkit-text-stroke-width: 1px;
      text-stroke: 1px;
      -webkit-text-stroke-color: #ff8000;
    }
  }
`


const StyledMenuIcon = styled(MenuIcon)`
  display: none;
  
  @media screen and (max-width: 1100px) {
      display: block;
      position: absolute;
      top: 18px;
      right: 16px;
  }
`

const MobileLanguageSelector = styled(LanguageSelector)`
  display: none;

  @media screen and (max-width: 1100px) {
      display: block;
      position: absolute;
      top: 25px;
      left: 16px;
  }

`

// Hook
// function useHover() {
//   const [value, setValue] = useState(false);

//   const ref = useRef(null);

//   const handleMouseOver = () => setValue(true);
//   const handleMouseOut = () => setValue(false);

//   useEffect(
//     () => {
//       const node = ref.current;
//       if (node) {
//         node.addEventListener('mouseover', handleMouseOver);
//         node.addEventListener('mouseout', handleMouseOut);

//         return () => {
//           node.removeEventListener('mouseover', handleMouseOver);
//           node.removeEventListener('mouseout', handleMouseOut);
//         };
//       }
//     },
//     [ref.current] // Recall only if ref changes
//   );

//   return [ref, value];
// }


const Header = ({ brand, tagline, location }) => {
  const {t} = useI18next();
  
  const context = React.useContext(I18nextContext);
  const language = context.language;
  const defaultLanguage = context.defaultLanguage;
  
  const [hamburgerOn, setHamburgerOn] = React.useState(false);
  // console.log("Location", context)
  
  const query = useStaticQuery(graphql`
      query {
        caseStories: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(case-stories)/"  }}) {
          edges {
            node {
              frontmatter {
                title
                background {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                }
                language
              }
              html
              id
            }
          }
        }
        
        ourModels: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(our-models)/" }} sort: {fields: frontmatter___order, order: ASC}) {
          edges {
            node {
              fields {
                slug
                # reference
                category
                # postId
                path
                langrefpath
                langrefslug
              }
              frontmatter {
                title
                language
              }
            }
          }
        }
          
        
      }
    `)
    

    let caseStoriesEdges = query.caseStories.edges
    let caseStoriesFilteredByLanguage = caseStoriesEdges.filter(edge=>edge.node.frontmatter.language == language)
    
    let ourModelsEdges = query.ourModels.edges
    let ourModelsFilteredByLanguage = ourModelsEdges.filter(edge=>edge.node.frontmatter.language == language)
  
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hovered6, setHovered6] = useState(false);
  // const toggleHover1 = () => setHovered1(!hovered1);
  // const toggleHover2 = () => setHovered2(!hovered2);
  // const toggleHover3 = () => setHovered3(!hovered3);
  // const toggleHover4 = () => setHovered4(!hovered4);
  // const toggleHover5 = () => setHovered5(!hovered5);
  // const toggleHover6 = () => setHovered6(!hovered6);
  
  const toggleHover1true = () => setHovered1(true);
  const toggleHover1false = () => setHovered1(false);
  const toggleHover2true = () => setHovered2(true);
  const toggleHover2false = () => setHovered2(false);
  const toggleHover3true = () => setHovered3(true);
  const toggleHover3false = () => setHovered3(false);
  const toggleHover4true = () => setHovered4(true);
  const toggleHover4false = () => setHovered4(false);
  const toggleHover5true = () => setHovered5(true);
  const toggleHover5false = () => setHovered5(false);
  const toggleHover6true = () => setHovered6(true);
  const toggleHover6false = () => setHovered6(false);
  
  return (
    <React.Fragment>
      <TopBarWrapper>
        <TopBar>
          <TopBarInner>
            <LanguageSelector />
          </TopBarInner>
        </TopBar>
      </TopBarWrapper>
      <SiteHeader>
        <SiteHeaderContainer>
          <SiteHeaderInner>
            <LogoWrapper>
              <Link to="/">
                <Logo>{brand}<sup className="trademark">&reg;</sup></Logo>
                <Tagline>{tagline}</Tagline>
              </Link>
            </LogoWrapper>
            <Divider orientation="vertical" flexItem style={{marginLeft: 20}}/>
            <NavWrapper className={(hamburgerOn === true) ? 'burgerOn' : 'burgerOff'}>
              <nav>
                <MainMenu>
                  <MainMenuItem className={hovered1 ? 'open-submenu' : ''} onMouseEnter={toggleHover1true} onMouseLeave={toggleHover1false}>
                    <Link to="/about-us" activeClassName="current-page">{t("MENU.ABOUT-US")}</Link>
                    
                      { context.originalPath === "/about-us/" ?
                        (<SubMenu>
                          <SubMenuItem><ScrollToLink to="mission" smooth={true} offset={-93} duration={500}>{t("MENU.MISSION")}</ScrollToLink></SubMenuItem>
                          <SubMenuItem><ScrollToLink to="approach" smooth={true} offset={-93} duration={500}>{t("MENU.APPROACH")}</ScrollToLink></SubMenuItem>
                          <SubMenuItem><ScrollToLink to="our-team" smooth={true} offset={-93} duration={500}>{t("MENU.OUR-TEAM")}</ScrollToLink></SubMenuItem>
                        </SubMenu>
                        ) : (
                        <SubMenu>
                          <SubMenuItem><Link to="/about-us#mission">{t("MENU.MISSION")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/about-us#approach">{t("MENU.APPROACH")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/about-us#our-team">{t("MENU.OUR-TEAM")}</Link></SubMenuItem>
                        </SubMenu>
                        )
                      }

                  </MainMenuItem>
                  <MainMenuItem title="Our model" className={hovered2 ? 'open-submenu' : ''} onMouseEnter={toggleHover2true} onMouseLeave={toggleHover2false} >
                    <Link to="/our-models" activeClassName="current-page" >{t("MENU.OUR-MODEL")}</Link>
                    <SubMenu>
                      {
                        ourModelsFilteredByLanguage.map((item, index)=>{
                          return (
                            <SubMenuItem key={`models-${index}`}><Link to={item.node.fields.langrefpath}>{item.node.frontmatter.title}</Link></SubMenuItem>
                          )
                        })
                      }
                    </SubMenu>
                  </MainMenuItem>
                  <MainMenuItem className={hovered3 ? 'open-submenu' : ''} onMouseEnter={toggleHover3true} onMouseLeave={toggleHover3false}>
                    <Link to="/our-services" activeClassName="current-page">{t("MENU.OUR-SERVICES")}</Link>
                    
                      { (language === "en") && 
                        <SubMenu>
                          <SubMenuItem><Link to="/our-services#workshops">{t("MENU.TARGETED-WORKSHOPS")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/our-services#proprietary-tools">{t("MENU.PROPRIETARY-TOOLS")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/our-services#full-engagement-model">{t("MENU.FULL-ENGAGEMENT-MODEL")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/our-services#keynote-speeches">{t("MENU.KEYNOTE-SPEECHES")}</Link></SubMenuItem>
                        </SubMenu>
                      }

                      { (language === "hu") && 
                        <SubMenu>
                          <SubMenuItem><Link to="/our-services#workshopok">{t("MENU.TARGETED-WORKSHOPS")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/our-services#codebreakers-eszkoztar">{t("MENU.PROPRIETARY-TOOLS")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/our-services#teljeskoru-tanacsadas">{t("MENU.FULL-ENGAGEMENT-MODEL")}</Link></SubMenuItem>
                          <SubMenuItem><Link to="/our-services#eloadasok">{t("MENU.KEYNOTE-SPEECHES")}</Link></SubMenuItem>
                        </SubMenu>
                      }

                   
                  </MainMenuItem>
                  <MainMenuItem className={hovered4 ? 'open-submenu' : ''} onMouseEnter={toggleHover4true} onMouseLeave={toggleHover4false}>
                    <Link to="/case-stories" activeClassName="current-page" >{t("MENU.CASE-STORIES")}</Link>
                    <SubMenu>
                      {
                        caseStoriesFilteredByLanguage.map((item, index)=>{
                          let contentId = slugify(item.node.frontmatter.title, {remove: /[…...?!]/g});
                          contentId = contentId.toLowerCase()
                          let prependLanguage = (defaultLanguage != language) ? `/${language}`:'';
                          return(
                            <SubMenuItem key={`caseStories-${index}`}><AnchorLink to={ `${prependLanguage}/case-stories#${contentId}`}>{item.node.frontmatter.title}</AnchorLink></SubMenuItem>
                          )
                        })
                      }
                    </SubMenu>
                  </MainMenuItem>
                  <MainMenuItem className={hovered5 ? 'open-submenu' : ''} onMouseEnter={toggleHover5true} onMouseLeave={toggleHover5false}>
                    <Link to="/test-yourself" activeClassName="current-page" >{t("MENU.TEST-YOURSELF")}</Link>
                    <SubMenu>
                      <SubMenuItem><Link to="/test-yourself/are-you-biased">{t("MENU.ARE-YOU-BIASED")}</Link></SubMenuItem>
                      <SubMenuItem><Link to="/test-yourself/test-your-cultural-awareness">{t("MENU.TEST-YOUR-CULTURAL-AWARENESS")}</Link></SubMenuItem>
                      <SubMenuItem><Link to="/test-yourself/find-your-companys-behavioural-type">{t("MENU.FIND-YOUR-COMPANYS-BEHAVIOURAL-TYPE")}</Link></SubMenuItem>
                    </SubMenu>
                  </MainMenuItem>
                  <MainMenuItem className={hovered6 ? 'open-submenu' : ''} onMouseEnter={toggleHover6true} onMouseLeave={toggleHover6false}>
                    <Link to="/publications" activeClassName="current-page" >{t("MENU.PUBLICATIONS")}</Link>
                     <SubMenu>
                      <SubMenuItem><Link to="/publications/articles-white-papers">{t("MENU.ARTICLES-WHITE-PAPERS")}</Link></SubMenuItem>
                      <SubMenuItem><Link to="/publications/presentations">{t("MENU.PRESENTATIONS")}</Link></SubMenuItem>
                      <SubMenuItem><Link to="/publications/books">{t("MENU.BOOKS")}</Link></SubMenuItem>
                    </SubMenu>
                  </MainMenuItem>
                  <MainMenuItem >
                    <Link to="/contact" activeClassName="current-page">{t("MENU.CONTACT")}</Link>
                  </MainMenuItem>
                </MainMenu>
              </nav>
            </NavWrapper>
            <StyledMenuIcon fontSize="large" onClick={() => setHamburgerOn(!hamburgerOn)}/>
            <MobileLanguageSelector className hideCurrent={true}/>
          </SiteHeaderInner>
        </SiteHeaderContainer>
      </SiteHeader>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header