import React from 'react';

import styled from "styled-components"
import Divider from '@material-ui/core/Divider';

const Header = styled.h3`
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: uppercase;
    text-align: center;
    color: ${props=>props.$colour};
    width: fit-content;
    margin: 0 auto 12px auto;
    background: ${props => props.$withBackground ? 'rgba(255,255,255,0.5)' : 'none'};
    padding: 5px 50px;
    
    @media screen and (max-width: 768px) {
        font-size: 25px;
        margin: 0 auto 12px auto;
    }
`

const SubHeader = styled.h4`
    font-size: 25px;
    margin-bottom: 12px;
    margin-top: 12px;
    color: ${props=>props.$subTitleColour};
    
     @media screen and (max-width: 768px) {
        font-size: 18px;
        margin: 12px;
    }
`

const StyledDivider = styled(Divider)`
    width: 84px;
    height: 3px;
    background-color: rgba(70,130,180,1);
    background-color: ${props=>props.$dividerColour};
    margin: 0 auto;
`

const SectionHeader = ({ title, subtitle, dividerColour, colour, subTitleColour, withBackground = true}) => {
    
    return (
        <React.Fragment>
            <Header $colour={colour} $withBackground={withBackground}>{title}</Header>
            <StyledDivider $dividerColour={dividerColour}/>
            <SubHeader $subTitleColour={subTitleColour}>{subtitle}</SubHeader>
          
        </React.Fragment>
    )
    
}

export default SectionHeader